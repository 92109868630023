@import "~bootstrap/scss/bootstrap.scss";
@import "./rainbows.scss";

:root {
  --font-color: #333;
  --link-hover-color: #000;
  --pre-color: rgb(33, 37, 41);

  --header-background: whitesmoke;
  --background-color: white;
  --hover-background: #faf0f0;

  --border-color: #{darken(white, 10%)};
  --accent-color: rgb(255, 62, 0);
}

.dark {
  --font-color: #ddd;
  --link-hover-color: #fff;
  --pre-color: white;

  --header-background: rgb(22, 27, 34);
  --background-color: rgb(13, 17, 23);
  --hover-background: #352f2f;

  --border-color: #{lighten(rgb(13, 17, 23), 20%)};
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: var(--font-color);
  background-color: var(--background-color);
}

pre {
  color: var(--pre-color);
}

input,
.form-control {
  background-color: var(--background-color);
  color: var(--font-color);
  &:focus {
    background-color: var(--background-color);
    color: var(--font-color);
  }
  border-style: solid;
  border-color: var(--border-color) !important;
}

*::selection {
  background: #333;
  color: white;
}

code {
  color: #f40;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.5em 0;
  font-weight: 400;
  line-height: 1.2;
}

a {
  color: inherit;

  &:hover {
    color: var(--link-hover-color) !important;
  }
}

.faded {
  color: #aaa;
}
.error {
  color: #c00;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 0.85em;
  font-weight: bold;
}

nav {
  border-bottom: 1px solid var(--border-color);
  font-weight: 300;
  &#header {
    background-color: var(--header-background);
  }

  &.navbar {
    padding: 0 1em;
  }

  img.logo {
    max-height: 1.5em;
    padding: 0.15em 0;
    display: inline-block;
  }

  h1 {
    display: inline-block;
    margin-bottom: 0;
    padding: 0.25em 0.5em;

    font-size: 1em;
    font-weight: 600;
    line-height: 1.5;
    border-right: 1px solid rgba(255, 62, 0, 0.1);
  }

  ul {
    margin: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding: 0;
  }

  .filter-bar {
    color: #999;
    // font-weight: 500;
  }

  /* clearfix */
  ul::after {
    content: "";
    display: block;
    clear: both;
  }

  li {
    list-style: none;
    float: left;
  }

  .selected::after {
    position: absolute;
    content: "";
    width: calc(100% - 0em);
    height: 2px;
    background-color: var(--accent-color);
    display: block;
    bottom: -1px;
  }

  a,
  button {
    position: relative;
    display: inline-block;

    text-decoration: none;
    margin: 0.25em 0.5em;
    display: block;

    background-color: inherit;

    &:hover {
      text-decoration: none !important;

      &::after {
        position: absolute;
        content: "";
        width: calc(100% - 0em);
        height: 2px;
        background-color: var(--accent-color);
        display: block;
        bottom: -1px;
      }
    }
  }

  .input-group {
    .form-control {
      border-color: initial;
    }
    .input-group-append,
    .input-group-prepend {
      button {
        margin: inherit;
        border-left: none;
        border-color: var(--border-color);
        &:hover {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

ol.issues {
  padding-left: 1.5rem;

  li {
    border-left: 0.25em solid var(--background-color);
    border-bottom: 1px dashed var(--border-color);

    $padding: 0.45rem;

    margin-bottom: 0;
    padding-top: $padding;
    padding-bottom: $padding;
    padding-left: 0.5rem;

    &:hover {
      background-color: var(--hover-background);
      border-left: 0.25em solid var(--accent-color);
    }
    h6 {
      a {
        &:visited {
          color: #999;
        }
      }
    }

    p {
      font-size: 0.8rem;
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }
}

.dropdown-menu {
  padding: 0;
  border-radius: 0;
  border: 1px dotted #999;
  background-color: var(--background-color);
  color: var(--font-color);

  .dropdown-item {
    padding: 0;
    width: inherit;
    color: inherit;
    font-size: 0.85rem;

    &:hover {
      background-color: inherit;
    }
  }
}

.closed {
  h6 {
    text-decoration: line-through;
  }
}

.emoji-mart-emoji span {
  vertical-align: text-bottom;
}

.link-button {
  border: none;
  color: inherit;
  font-weight: inherit;
  cursor: pointer;
  display: inline;
  // margin: 0;
  padding: 0;
  &:hover,
  &:focus {
    color: var(--link-hover-color);
    text-decoration: underline;
  }
}

.pointer {
  cursor: pointer;
}

.close-link {
  display: inline-block;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border-width: 3px;
  border-style: solid;
  border-radius: 100%;
  background: -webkit-linear-gradient(
      -45deg,
      transparent 0%,
      transparent 46%,
      var(--background-color) 46%,
      var(--background-color) 56%,
      transparent 56%,
      transparent 100%
    ),
    -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, var(
            --background-color
          )
          46%, var(--background-color) 56%, transparent 56%, transparent 100%);
  background-color: #999;
  border-color: #999;
  transition: all 0.3s ease;
  vertical-align: middle;

  &:hover {
    border-color: var(--link-hover-color);
    background-color: var(--link-hover-color);
  }
}

.sidebar {
  h4 {
    font-size: 0.85rem;
    text-transform: lowercase;
    color: #999;
    border-bottom: 1px solid #ddd;
    font-weight: 400;
    margin-top: 0.5rem;
    margin-bottom: 0.7r5em;
  }
  .strikethrough {
    text-decoration: line-through;
  }
}
